@import './variables.scss';

:root {
  --sideBarWidth: 78px;
  --sideBarWidthActive: 200px;
}

.contactPageContainer {
  position: relative;
  min-height: 100vh;
  width: 100%;
  //   overflow: hidden;
  .sidebar {
    .padLR {
      padding: 0px 24px;
    }
    .padT {
      padding-top: 20px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    border-right: 1px solid #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--sideBarWidth);
    background: var(--black-color);
    // padding: 20px 14px 20px 14px;
    padding: 0px;
    z-index: 99;
    transition: all 0.5s ease;

    .addGroup {
      margin-top: 0px;
      opacity: 0;
      p {
        font-size: 18px;
        font-weight: 700;
        color: $fade555;
      }
    }
    .addContact,
    .addGroup img {
      background: rgba(32, 151, 246, 0.04);
      border-radius: 5px;
      height: 25px;
      width: 25px;
    }
    .contactListMenu ul li {
      padding: 10px 20px;
    }
    &.active {
      @media (min-width: 700px) {
        width: var(--sideBarWidthActive);
        & ~ .contentContainer {
          width: calc(100% - var(--sideBarWidthActive));
          left: var(--sideBarWidthActive);
        }
        .addGroup {
          opacity: 1;
        }
        .addContact {
          opacity: 0;
        }
        & ul {
          li {
            margin-bottom: 0;
            .listMenuContent {
              transition: 0s;
              display: block;
              pointer-events: auto;
            }
            .mobileMenuItem {
              // opacity: 0;
              display: none;
            }
          }
        }
      }
    }
    & ul {
      margin-top: 40px;
      li {
        position: relative;
        // height: 50px;
        margin-bottom: 0;
        width: 100%;
        list-style: none;
        .listMenuContent {
          display: none;
          pointer-events: none;
          transition: all 0.3s ease;

          p {
            font-size: 16px;
            font-weight: 600;
            color: $fade555;
          }
          .faded {
            color: $fade3;
            font-weight: 500;
            font-size: 14px;
          }
        }
        .mobileMenuItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .linkName {
            background-color: #f3f4fc;
            // padding: 5px;
            text-align: center;
            width: 35px;
            height: 35px;
            border-radius: 10px;
            color: $primaryColor;
            font-weight: 700;
          }
          // .tooltip {
          //   position: absolute;
          //   left: 125px;
          //   top: 0;
          //   transform: translate(-50%, -50%);
          //   border-radius: 6px;
          //   height: 35px;
          //   width: auto;
          //   padding: 0px 7px 0px 7px;
          //   background: #fff;
          //   line-height: 35px;
          //   text-align: center;
          //   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          //   transition: 0.4s;
          //   opacity: 0;
          //   pointer-events: none;
          //   display: block;
          //   white-space: nowrap;
          //   &::before {
          //     content: '';
          //     width: 0;
          //     height: 0;
          //     border-top: 10px solid transparent;
          //     border-bottom: 10px solid transparent;
          //     border-right: 10px solid #fff;
          //     position: absolute;
          //     left: -8px;
          //     top: 7px;
          //   }
          // }
        }
        // &:hover .tooltip {
        //   transition: all 0.5s ease;
        //   opacity: 1;
        //   top: 50%;
        // }
      }
    }
  }
  .contentContainer {
    // position: absolute;
    height: 100%;
    width: calc(100% - var(--sideBarWidth));
    margin-left: auto;
    left: var(--sideBarWidth);
    padding: 20px;
    // box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    .heading {
      margin-bottom: 10px;
      h3 {
        font-size: 18px;
        color: $fade555;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .contentEditable {
        font-size: 14px;
        color: $fade3;
        font-weight: 500;
      }
    }
    .actionTab {
      margin: 1.5rem 0 2rem 0;
      .searchInput {
        background-color: #f7f7f7;
        border-radius: 4px;
        color: #aaaaaa;
        & * {
          background-color: $lighterBorder;
        }
      }
      .actions {
        button {
          min-width: 130px;
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          &:hover {
            color: #fff;
          }
        }
        .ghostBtn {
          border: 1px solid $shade1;
          &:hover {
            color: #000;
          }
        }
      }
      @media (max-width: 970px) {
        .flex-space-btw {
          display: block;
          .actions {
            margin-top: 20px;
            @media (max-width: 650px) {
              .ant-space {
                display: block;
                .ant-btn {
                  min-width: 80%;
                  margin: auto;
                  margin-bottom: 10px;
                  @media (max-width: 450px) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .contactTableContainer {
      border: 1px solid $lightBorder;
      padding: 10px;
      table {
        .ant-table-thead {
          th {
            color: $fade4;
            font-size: 18px;
            font-weight: 400;
          }
        }
        td {
          color: $fade5;
          font-size: 16px;
          font-weight: 400;
          .tableActionBtns {
            & button {
              border: 2px solid $lightBorder;
              color: $fade4;
              font-size: 12px;
              border-radius: 4px;
              &:first-child {
                margin-right: 15px;
              }
              &:last-child {
                color: #f45b5b;
              }
              &.allcontact-edit_btn {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }
}
