@import './variables.scss';

.emmaccen {
  .helpCenter {
    .landing {
      height: 500px;
      width: 100%;
      display: flex;
      align-items: center;
      background: linear-gradient(
        275.93deg,
        #273df2 -32.63%,
        rgba(255, 255, 255, 0.53125) 23.03%,
        rgba(255, 255, 255, 0) 100.71%
      );
      .jumbotron {
        width: 50%;
        h1 {
          font-size: 45px;
        }
      }
    }
    .articles {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      @media (max-width: 1050px) {
        .textCenter {
          text-align: left;
        }
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 750px) {
          grid-template-columns: repeat(2, 1fr);

          @media (max-width: 500px) {
            grid-template-columns: 1fr;
          }
        }
      }
      .card {
        .cardIcon {
          justify-content: flex-end;
          .anticon {
            padding: 4px;
            background: rgba(33, 51, 197, 0.05);
            box-shadow: 0px 4px 12px rgba(39, 61, 242, 0.1);
            border-radius: $radius;
            svg {
              color: #273df2;
            }
          }
        }
        h2 {
          font-size: 20px;
        }
      }
    }
    @media (max-width: 900px) {
      .landing {
        height: 400px;
        .jumbotron {
          width: 80%;
        }
        @media (max-width: 600px) {
          .header h1 {
            font-size: 30px;
          }
          .jumbotron {
            width: 90%;
          }
        }
      }
    }
  }
}
