@import './variables.scss';
.emmaccen {
  .pricingContainer {
    // border: 1px solid red;
    @media (max-width: 600px) {
      .pricingHeader {
        // width: 80%;
        // margin: auto;
        h1 {
          font-size: 27px;
        }
      }
    }
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      align-items: start;
      @media (max-width: 900px) {
        grid-template-columns: 1fr 1fr;

        @media (max-width: 600px) {
          grid-template-columns: 1fr;
          gap: 60px;
          .sticky {
            position: sticky;
            top: 20px;
            background-color: #0b0d17;
            padding: 15px 10px;
            border-radius: $radius;
            * {
              color: #fff;
            }
          }
        }
      }
      .detailsContainer {
        border: 2px solid rgba(39, 61, 242, 0.3);
        background-color: #fff;
        padding: 20px;
        border-radius: $radius;
        .contentHeader {
          h1 {
            font-size: 25px;
            margin-bottom: 10px;
          }
          .price {
            border-radius: 15px;
            background-color: rgba(212, 216, 252, 0.2);
            padding: 5px 10px;
            h1 {
              font-size: 20px;
              margin-bottom: 5px;
            }
          }
          p {
            font-size: 13px;
          }
        }
      }
      .recomended {
        position: relative;
        .overlay {
          position: absolute;
          top: -3.5%;
          left: 0;
          right: 0;
          width: 100px;
          margin: auto;
          text-align: center;
          content: '';
          padding: 5px;
          font-size: 12px;
          color: #fff;
          background-color: #000;
          border-radius: 7px;
        }
      }
      button {
        width: 100%;
        margin-top: 20px;
      }
      .buttonDark {
        color: #fff;
        background-color: $primaryColorLight;
      }
      .plan {
        margin: 10px 0px;
      }
      .iconActive,
      .iconSoft {
        svg {
          background-color: #333;
          border-radius: 50%;
          color: #fff;
          font-size: 20px;
        }
      }
      .iconSoft {
        svg {
          background-color: #999;
        }
      }
    }
  }
}
