@import './variables.scss';
.emmaccen {
  .editorContainer {
    .editorInputArea {
      // width: 80%;
      margin: 0px auto;
      padding-bottom: 50px;
      @media (max-width: 1000px) {
        // width: 90%;
        @media (max-width: 700px) {
          // width: 95%;
        }
      }
      .ckeditorStyles {
        h1 {
          font-size: 3.5rem;
        }
        h2 {
          font-size: 3rem;
        }
        h3 {
          font-size: 2.5rem;
        }
        h4 {
          font-size: 2rem;
        }
        h5 {
          font-size: 1.5rem;
        }
        h6 {
          font-size: 1rem;
        }
        .ck-content {
          min-height: 400px;
          max-height: 500px;
          overflow-y: auto;
          padding: 15px 20px;
        }
        .ck-toolbar {
          position: sticky;
          top: 0;
        }
        .ck-content,
        .ck-toolbar {
          border: 1px solid $shade1;
        }
      }
    }
  }
}
