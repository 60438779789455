@import './variables.scss';

.hideMe {
  display: none;
  -webkit-animation: hideMation 0.4s forwards;
  animation: hideMation 0.4s forwards;
}
@keyframes hideMation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes hideMation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
.revealMe {
  display: block;
  -webkit-animation: revealMation 0.6s forwards;
  animation: revealMation 0.6s forwards;
}
@keyframes revealMation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    display: block;
  }
}
@-webkit-keyframes revealMation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.sidebar {
  // max-height: 1000vh;
  // overflow-y: scroll;
  // overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  max-height: 100%;
  padding: 1rem 11px;
  border-right: 1px solid #eaeaea;
  z-index: 6;
  background: #fff;
  // box-shadow: 0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08);
  border-right: 1px solid #eaeaea;
  transition: width 0.4s;

  & > div {
    .sidebar__logo {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .logo {
        width: 38px;
        height: 38px;
        text-align: center;
      }

      .logo-title {
        font-weight: 600;
        font-size: 21.039px;
        line-height: 22px;
        color: $primaryColor;
        margin-left: 12px;
      }
    }

    .sidebar__menu {
      transition: width 0.4s;
      & > div {
        display: block;
        text-align: right;
        transition: width 0.5s;
        button {
          cursor: pointer;
        }
      }
      a:link {
        text-decoration: none;
      }
      &__item.active {
        background: #f2f3fe;
        .sidebar__menu__item__icon,
        .sidebar__menu__item__text {
          color: $primaryColor;
        }
      }
      &__item {
        display: flex;
        align-items: center;
        margin: 0.7rem 0;
        border-radius: 3.65895px;
        transition: all 0.3s ease-in-out;
        width: 100%;

        &:hover {
          background: #f2f3fe;
        }
        &:hover > &__icon,
        &:hover > &__text {
          color: $primaryColor;
        }

        &__icon {
          width: 40px;
          color: $fade4;
          transition: all 0.3s ease-in-out;
        }
        &__text {
          font-size: 16px;
          line-height: 22px;
          transition: all 0.3s ease-in-out;
          margin-left: 12px;
          margin-right: 12px;
          padding-left: 7.32px;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  // .sidebar {
  //   display: none;
  // }
}
