@import './variables.scss';
.emmaccen {
  // .campaigne {
  // .campaigneTable {
  //   .ant-switch {
  //     padding: 0px;
  //     border-radius: 100px !important;
  //   }
  //   .actionIcons {
  //     display: flex;
  //     font-size: 18px;
  //     svg {
  //       margin-right: 20px;
  //     }
  //     .deleteIcon {
  //       color: #f45b5b;
  //     }
  //   }
  // }
  // }

  // for the campaigns homepage
  #campaignHeader {
    margin-bottom: 50px;
    @media (max-width: 800px) {
      display: block;
      h2 {
        margin-bottom: 20px;
      }
      .sorter {
        display: inline-block;
        margin-bottom: 15px;
      }
      @media (max-width: 450px) {
        .rightSection {
          display: block;
          .btnPrimary {
            margin: 15px 0px;
            width: 100%;
          }
        }
      }
    }
  }
  .campaignTableData {
    overflow-x: auto;
    .sideMenu {
      .ant-menu-item-selected {
        background-color: transparent;
        background: rgba(198, 203, 242, 0.2);
        border-radius: $radius;
        &::after {
          display: none;
        }
      }
    }
    .tableList {
      // min-width: 500px;
    }
  }
  .antdTable {
    border: 1px solid $shade1;
    padding: 0px 10px;
    margin: 20px 0px;
    .ant-divider {
      border: none;
      display: none;
    }
    td {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
  }
  .modalFormFooter {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    button {
      min-width: 80px;
      &:first-child {
        margin-right: 10px;
        border: 1px solid #efefef;
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .campaignDetails {
    .layoutWrapper {
      // width: 93%;
      margin: 0 auto;
      padding: 0px 0px;
      @media (max-width: 1000px) {
        // width: 90%;
        @media (max-width: 700px) {
          // width: 95%;
        }
      }
      .ant-tabs-content-holder {
        // width: 93%;
        // margin: 0 auto;
      }
    }
    @media (max-width: 1000px) {
      .ant-tabs > .ant-tabs-nav,
      .ant-tabs > div > .ant-tabs-nav {
        display: block;
        .ant-tabs-extra-content {
          margin-top: 20px;
          @media (max-width: 500px) {
            .flex {
              display: block;
              .actionBtns {
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    .receipientActionBtns {
      button {
        border: 1px solid #efefef;
        // padding: ;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
    .templateContaner {
      max-height: 500px;
      overflow-y: auto;
      .grid2 {
        grid-template-columns: 55% 40%;
      }

      svg {
        transition: $ease;
        margin: 0px 5px;
        font-size: 18px;
      }
      .mailList {
        max-height: 450px;
        overflow-y: auto;
      }
      @media (max-width: 900px) {
        height: auto;
        & .grid2 {
          grid-template-columns: 1fr;
        }
      }
      .mailList {
        .mailFilterContainer {
          margin-bottom: 50px;
          @media (max-width: 500px) {
            grid-template-columns: repeat(1, 1fr);
            .filterCard {
              min-width: 80%;
              max-width: 90%;
              justify-self: center;
            }
          }
          .filterCard {
            cursor: pointer;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 10px;
            .icon {
              height: 22px;
              width: 22px;
              margin-bottom: 10px;
            }
            &.active.mail {
              .value span {
                background: rgba(55, 179, 74, 0.05);
                color: #37b34a;
              }
            }
            &.active.draft {
              .value span {
                background: rgba(255, 193, 7, 0.1);
                color: #ffc107;
              }
            }
            &.active.deleted {
              .value span {
                background: rgba(#db2222, 0.05);
                color: #db2222;
              }
            }
            .value {
              p {
                font-weight: 500;
                font-size: 12px;
                color: $descriptionTextColor;
              }
              span {
                padding: 1px 8px;
                margin-left: 10px;
                color: $descriptionTextColor;
                background: #f2f2f2;
                border-radius: 4px;
              }
            }
            .desc {
              color: $descriptionTextColor;
              font-weight: 400;
              font-size: 10px;
              margin-top: 10px;
            }
          }
        }

        .card {
          cursor: pointer;
          border: 1px solid #f2f2f2;
          padding: 15px;
          margin-bottom: 4px;
          box-shadow: none;
          &.selected {
            border: 1px solid #273df2;
            &:hover {
              padding: 15px !important;
            }
          }
          &:hover {
            // border: 1px solid #273df2;
            box-shadow: 0px 4px 14px rgba(39, 61, 242, 0.05);
            transition: $ease;
          }
          .bold {
            margin-bottom: 10px;
            font-size: 18px;
          }
          .subject {
            font-size: 14px;
            color: #383838;
          }
        }
        .mail {
          .actionBtn {
            display: none;
            @media (max-width: 900px) {
              display: block;
            }
          }
          &:hover .actionBtn {
            transition: $ease;
            display: block;
          }
        }
      }
    }
    @media (max-width: 900px) {
      .emptyState {
        svg {
          height: 300px;
          width: 300px;
        }
        @media (max-width: 500px) {
          svg {
            height: 250px;
            width: 250px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
    .settingsTab {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      .label {
        font-size: 16px;
        font-weight: 500;
        color: $fade555;
      }
      @mixin wrapInput {
        & .inputs {
          display: grid;
          grid-template-columns: 45% 45%;
          gap: 10%;
          @media (max-width: 400px) {
            grid-template-columns: 1fr;
          }
        }
      }
      input {
        border-radius: 4px;
      }
      .topGrid {
        display: grid;
        grid-template-columns: 39% 59%;
        gap: 2%;
        // align-items: flex-start;
        @media (max-width: 800px) {
          grid-template-columns: 1fr 1fr;
          @media (max-width: 650px) {
            grid-template-columns: 1fr;
          }
        }
        & .formOne {
          @include wrapInput();
          grid-template-columns: 25% 65%;
          // & .inputs {
          //   display: grid;
          //   gap: 10%;
          //   @media (max-width: 400px) {
          //     grid-template-columns: 1fr;
          //   }
          // }
        }
        & .formTwo {
          @include wrapInput();
        }
      }
      .lowerGrid {
        width: 65%;
        @include wrapInput();
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .border {
        padding: 20px;
      }
      .variablesContainer {
        padding: 10px;
        border-radius: $radius;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
      }

      .collapseHeader {
        & > div {
          margin-bottom: 5px;
          & > span:nth-of-type(1) {
            font-size: 16px;
            font-weight: 600;
            color: $title2;
          }
          .paid_tag {
            font-size: 10px;
            background-color: $lightBorder;
            padding: 1px 10px;
            font-weight: 600;
            color: $fade4;
            border-radius: 2px;
          }
        }
        & > p {
          font-weight: 500;
          color: $descriptionTextColor;
        }
      }
      .form_container {
        margin: 1rem 0;
        .form_inputs {
          & > .ant-row.ant-form-item {
            margin-bottom: 0;
            margin-right: 30px;
            .ant-form-item-label label {
              font-weight: 400;
            }
            .ant-form-item-control-input-content {
              input[type='number'] {
                width: 100px;
              }
              input {
                color: $fade8;
              }
            }
          }
          .ant-form-item .ant-select {
            width: 190px;
            .ant-select-selector {
              height: 40px !important;
              .ant-select-selection-item {
                line-height: 30px;
                color: $fade8;
              }
            }
          }
        }
        .form_submit button {
          padding-left: 25px;
          padding-right: 25px;
          background: $fade5;
        }
      }
    }
    .formsTab {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      .contentContain {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
        border-bottom: 1px solid $shade1;
        .left_disp {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          img {
            width: 100px;
            height: 60px;
          }
          & > div {
            margin-left: 2rem;

            & > span {
              font-size: 16px;
              font-weight: 500;
              color: $title2;
            }
            p {
              color: $descriptionTextColor;
            }
          }
        }
      }
    }
  }
  .createNewEmailModal {
    .card {
      transition: $ease;
      padding: 10px;
      min-height: 70px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &:hover {
        transform: translateX(-10px);
      }
      a {
        text-decoration: none;
      }
    }
  }
  .mailAnalyticsContainer {
    padding: 5%;
    .titleHead {
      font-weight: 600;
      font-size: 18px;
      color: $fade555;
      margin-bottom: 40px;
    }
    .analytics {
      .grid-3 {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        .item {
          flex: 1;
          min-width: 90px;
          .title {
            font-weight: 700;
            font-size: 20px;
            color: $descriptionTextColor;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            color: $fade555;
          }
        }
      }
      .grid-2 {
        width: 100%;
        margin-top: 1rem;

        @media (max-width: 500px) {
          flex-wrap: wrap;

          & > div {
            width: 100% !important;

            & > div {
              width: 180px;
              margin: 0 auto;
            }
          }
        }

        & > div {
          width: 50%;

          & > div {
            margin-top: 10px;
            span {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 8px;
            }
            p {
              margin-right: 8px;
            }
            h3 {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .campaign-information {
    .reporting_sect {
      width: 50%;
      border-right: 1px solid $shade1;
      // margin: 0 1rem;
      margin-left: -4px;
      padding-top: 25px;
      & > h3 {
        font-weight: 500;
        margin-bottom: 2rem;
      }

      & > div {
        flex-wrap: wrap;
        margin-top: 2rem;
        .g-items {
          min-width: 100px;
          // margin: 0 auto;
          margin-bottom: 2.5rem;
          justify-content: space-between;
          flex-wrap: wrap;

          p {
            color: $fade555;
            display: flex;

            img {
              margin-left: 0.3rem;
            }
          }
          h3 {
            font-weight: 500;
            font-size: 32px;
            line-height: 138%;
            color: $title2;
          }
        }
      }
    }
    .information_sect {
      width: 50%;
      margin: 0;
      padding-top: 25px;
      // border: 1px solid $shade1;
      border-left: none;
      margin-left: 2.5rem;
      & > h3 {
        font-weight: 500;
        margin-bottom: 2rem;
      }

      & > div {
        flex-wrap: wrap;
        margin-top: 2rem;
        .g-items {
          // min-width: 100px;
          // margin: 0 auto;
          flex-grow: 1;
          margin-bottom: 1.5rem;
          justify-content: space-between;
          flex-wrap: wrap;
          min-height: 61px;
          &:first-child {
            max-width: 180px !important;
            min-width: 180px !important;
          }

          p {
            color: $fade555;
          }
          h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 175%;
            color: $title2;
          }
        }
      }
    }

    @media (max-width: 815px) {
      & > div {
        flex-direction: column;
      }

      .reporting_sect {
        border-right: none;
        width: 100%;
      }
      .information_sect {
        width: 100%;
        margin-left: -4px;
      }
    }
  }
}
