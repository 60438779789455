@import './variables.scss';

.emmaccen {
  .signupContainer {
    .carousel {
      height: 120vh !important;
    }
    .grid2 {
      @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 0px;
      }
    }
  }
  .loginPageContainer {
    .title {
      font-size: 36px;
    }
    .margin-t-b {
      margin: 30px 0px;
    }
    min-height: 100vh;
    .loginSections {
      .w-50 {
        width: 50%;
      }
      .carouselArea {
        @media (max-width: 800px) {
          display: none;
        }
        .carousel {
          height: 103vh;
          background-color: rgba(39, 61, 242, 1);
          .centered {
            height: 100%;
          }
          .carouselContent {
            width: 70%;
            @media (max-width: 950px) {
              width: 90%;
            }
            h1 {
              margin-top: 15px;
              font-size: 22px;
            }
            p {
              color: #d8d8d8;
              margin-top: 15px;
            }
            * {
              color: #fff;
            }
          }
          img {
            height: 300px;
            margin: auto;
          }
        }
      }
      .formArea {
        @media (max-width: 800px) {
          padding: 5%;
          width: 100%;
        }
        .formContent {
          height: 100%;
          form {
            button {
              width: 100%;
            }

            .ant-btn-primary[disabled] {
              color: rgb(210, 210, 210);

              &:hover {
                color: rgb(96, 96, 96);
              }
            }
            .tc_pp {
              font-weight: 500;
              color: $fade7;
            }
          }
        }
      }
    }
  }
  @media (max-width: 800px) {
    .formArea {
      min-height: 100vh;
      & .title {
        font-size: 23px;
      }
    }
  }
}
