@import './variables.scss';
.emmaccen {
  @media (max-width: 750px) {
    .header2 h1 {
      font-size: 25px;
      line-height: normal;
    }
    @media (max-width: 650px) {
      .header h1 {
        font-size: 25px;
        line-height: normal;
      }
    }
  }
  .checkList {
    svg {
      color: $useGreen;
    }
    p {
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .cutDownExpense {
    @media (max-width: 1150px) {
      .planList {
        p {
          font-size: 14px;
        }
        @media (max-width: 450px) {
          display: block;
        }
      }
      @media (max-width: 1000px) {
        .container2 {
          width: 90%;
          @media (max-width: 900px) {
            .grid2 {
              grid-template-columns: 1fr;
            }
            width: 80%;
            @media (max-width: 600px) {
              width: 90%;
              .header2 h1 {
                font-size: 35px;
              }
              .sideImg {
                width: 90%;
                margin: auto;
              }
            }
          }
        }
      }
    }
    h1 {
      font-size: 50px;
      font-weight: 500;
    }
    p {
      font-size: 18px;
    }
    .sideImg {
      height: 400px;
      width: 400px;
    }

    .cutIcon {
      top: 0px;
      left: 10%;
    }
    .gif {
      svg {
        margin-left: 20px;
        height: 30px;
        width: 30px;
      }
    }
    .planList {
    }
    // .ant-input,
    // .ant-btn-primary {
    //   height: 50px;
    // }
  }

  .touchPoints {
    .cardList {
      margin-top: 25px;
      // align-items: baseline;
      @media (max-width: 850px) {
        padding: 30px 0%;
        .centered {
          flex-wrap: wrap;
          .card {
            margin: 30px;
            width: 40%;
            @media (max-width: 670px) {
              width: 90%;
              margin-top: 0px;
            }
          }
        }
      }
      .card {
        width: 350px;
        margin-right: 50px;
        margin-top: 50px;
        // &:last-child {
        //   margin-right: 0px;
        // }
        h2 {
          color: $primaryColorLight;
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }

  .subscribers {
    .checkList {
      .flex {
        margin-bottom: 15px;
      }
    }
    @media (max-width: 850px) {
      .grid2 {
        grid-template-columns: 1fr;
        // .header h1 {
        //   font-size: 25px;
        // }
        .centered {
          justify-content: flex-start;
        }
      }
    }
  }
  .shedule {
    .grid2 {
      gap: 30px;
      margin-bottom: 50px;
    }
    .underline {
      bottom: 0px;
    }
    @media (max-width: 850px) {
      .grid2 {
        display: flex;
        flex-direction: column;
        .campagineImg {
          width: 100%;
          height: 300px;
          object-fit: contain;
        }
      }
      .grid2:nth-child(odd) {
        .campagineImg {
          order: 2;
        }
      }
    }
    .campagineImg {
      height: 400px;
    }
  }

  .subscribe {
    text-align: center;
    .blueBg {
      padding: 10% 20%;
      border-radius: $radius;
      @media (max-width: 700px) {
        padding: 10% 5%;
        .header h1 {
          font-size: 18px;
        }
      }
    }
    h1,
    p {
      color: #fff;
    }
    h1 {
      font-size: 27px;
      line-height: 35px;
    }
  }
  .container2 {
    @media (max-width: 1000px) {
      width: 90%;
      @media (max-width: 900px) {
      }
    }
  }
}
