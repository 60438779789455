@import './variables.scss';

.choosePlanModalWrapper {
  color: #000000;

  .ant-modal-content {
    padding: 30px 70px;
    border-radius: 10px;
    .ant-modal-header {
      border-bottom: none;
      text-align: center;
      margin-bottom: 10px;
      padding-bottom: 0px;
      .ant-modal-title {
        font-size: 24px;
        .name {
          font-weight: 600;
          font-size: 24px;
        }
      }
    }
    .ant-modal-body {
      padding-top: 0px;
    }
  }
  .warningMessage {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-bottom: 15px;
  }
  .selectPlanHeader {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }

  .paygWrapper {
    padding: 15px;
    border: 2px solid #c7c7c7;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    h5 {
      font-weight: 600;
      font-size: 14px;
      color: #8e8e8e;
      margin-bottom: -3px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      color: #555555;
    }
  }

  .divider {
    width: 100%;
    margin: 10px auto 15px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-weight: 600;
      font-size: 18px;
      color: #8e8e8e;
    }
  }

  .plans {
    .planType {
      border: 2px solid #c7c7c7;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 10px;
      cursor: pointer;
      h5 {
        font-weight: 600;
        font-size: 12px;
        color: #8e8e8e;
        text-transform: uppercase;
      }
      .creditsPriceWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .credit-slots {
          font-weight: 600;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1px;
          .emailCredits {
            letter-spacing: 0;
            font-weight: 600;
            font-size: 12px;
            color: #717171;
          }
        }
        .priceBox {
          height: 50px;
          // width: 60px;
          padding: 0px 10px;
          background: #f6f7fe;
          border-radius: 10px;
          color: #000000;
          font-weight: 600;
          display: grid;
          place-content: center;
          p {
            font-size: 16px;
            margin-top: 0;
          }
          .cycle {
            font-size: 12px;
          }
        }
      }
    }
    .comparePlans {
      display: flex;
      justify-content: flex-end;
      font-weight: 500;
      font-size: 12px;
      color: #273df2;
    }
    .comparePlans:hover {
      text-decoration-line: underline;
    }

    .skipForLater {
      display: flex;
      padding-top: 20px;
      p {
        margin: auto;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        text-decoration-line: underline;
        color: #273df2;
        cursor: pointer;
      }
    }
  }

  .selected_plan__border {
    border: 2px solid #273df2 !important;
  }

  @media (max-width: 900px) {
    .creditsPriceWrapper {
      align-items: self-end !important;
    }
    .credit-slots {
      flex-wrap: wrap !important;
    }
  }

  // *****************************************
  // Max Width - 425px
  // *****************************************
  @media (max-width: 425px) {
    .ant-modal-content {
      padding: 0px !important;
      padding-top: 10px !important;
    }
    .divider {
      width: 90% !important;
      p {
        font-size: 16px !important;
      }
      svg {
        width: 110px !important;
      }
    }
  }
}
