@import 'styles/variables.scss';

.payment_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  max-width: 450px;
  // padding-left: 20px;
  padding: 15px 32px;
  padding-bottom: 0;
  margin-bottom: 0px;
  box-shadow: 0px 6px 38.4354px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-left: 20px;

  p {
    margin: 0;
  }

  .payment_form__header {
    text-align: center;
    margin-top: 10px;
    color: #383838;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 24px;
    span {
      font-weight: 600;
      color: #37b34a;
    }
  }

  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #383838;
    margin-bottom: 20px;
  }

  .accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 30px;
    cursor: pointer;
    h6 {
      margin-bottom: 0px;
    }

    .chevronFaceUp {
      transform: none;
    }
    .chevronFaceDown {
      transform: rotate(180deg);
    }
  }
  .billingInfo {
    font-weight: 600;
    font-size: 16px;
    color: #8e8e8e;
    margin-bottom: 20px;
  }
}

.saved_cards {
  .empty {
    border: 2px solid #c7c7c7;
    border-radius: 10px;
    padding: 15px 10px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
}

.paystack_signature {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
  color: #555555;
  font-size: 14px;
  svg {
    margin-left: 3px;
    width: 10px;
    height: 12px;
  }
}
