@import './variables.scss';
.emmaccen {
  .ant-modal-body {
    form {
      input[type='text'],
      input[type='number'] {
        border: 1px solid $shade1;
        border-radius: 4px;
        height: 45px;
      }
    }
  }
  .pad30 {
    margin-top: 30px;
  }
  .addNewContactModal,
  .addNewRecipient {
    .title {
      font-weight: 600;
      font-size: 18px;
      color: $fade5;
      margin-top: 10px;
    }
    // label {
    //   font-weight: 500;
    //   font-size: 14px;
    //   color: $fade5;
    // }
    .actionBtns {
      button {
        min-width: 100px;
      }
      & button:first-child {
        border: 1px solid $shade1;
      }
    }
    .ant-select-selector {
      font-weight: 400;
      font-size: 14px;
      color: $fade555;
      //   background-color: $lightBorder;
    }
    .newRecipientsDir {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      span {
        font-weight: bold;
      }
    }
  }
  .createContactGroupModal {
    .title {
      font-weight: 600;
      font-size: 18px;
      margin: 30px 0px 20px 0px;
      color: $fade5;
    }
    .hint {
      font-weight: 500;
      font-size: 14px;
      color: $fade5;
      margin-top: 20px;
    }
  }
}
