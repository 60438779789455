@import './variables.scss';
.emmaccen {
  .resetPasswordContainer {
    .carousel {
      height: 100vh !important;
    }
    .formContent {
      padding: 10px 0px;
      & > div {
        height: 100%;
      }
    }
  }
}
