@import './variables.scss';

.emmaccen {
  .verifySectionContainer {
    width: 100%;
    min-height: 100vh;
  }
  .verifySectionInnerContainer {
    padding: 32px 40px;
    box-shadow: 0px 5px 50px rgba(5, 1, 21, 0.05);
    border-radius: 10px;
    max-width: 450px;
    margin: auto;
    h3 {
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
    .textWrapper {
      padding: 12px 16px;
      background: rgba(8, 207, 135, 0.1);
      border-radius: 5px;
    }
    button {
      &:disabled {
        color: #fff;
      }
      &:hover {
        background-color: $primaryColor;
      }
    }
  }
}
