@import './variables.scss';
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
// ÷
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primaryColorLight;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primaryColor;
}

// Remove input[type:numer] arrow/spinner
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  font-family: 'Inter';
  // max-width: 1400px;
  margin: auto !important;
}

.customNotification {
  border-radius: 10px;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
  .ant-notification-notice-message {
    color: $fade555;
    font-weight: 600;
    font-size: 16px;
    margin-left: 36px;
  }
  .ant-notification-notice-icon {
    margin-left: 0;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ant-notification-notice-description {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin-left: 0;
    margin-top: 10px;
  }
}
.pageContent {
  position: absolute;
  min-height: 90vh;
  // top: 60px;
  right: 0;
  overflow: auto;
  @media (max-width: 800px) {
    // margin-left: 64px;
    width: 100%;
  }

  @media (max-width: 600px) {
    margin-left: 0;
  }
}

.cust-modal-class.whats-new_modal-pop {
  .ant-modal-body {
    padding: 0;
    padding-bottom: 1rem;
    .slick-slider {
      .slick-list {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    .slider-ctr {
      // margin: 1rem;
      margin-right: 1rem;
      button {
        padding: 10px 12px;
        background: $lightBlueBg;
        border-radius: 4px;

        &.imready {
          padding: 7px 22px;
          background: $primaryColor;
          color: #fff;
        }
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .newUpdates {
      &-contain {
        height: 508px;
        width: 100%;
      }

      &-img_banner {
        height: 269px;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
        }
      }
      &-descrp {
        height: 100%;
        width: 100%;
        padding: 0 1rem;

        &_tag {
          margin-top: 1rem;
          p {
            width: fit-content;
            background-color: $lightRedBg;
            border-radius: 4px;
            padding: 10px 14px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $useDeepRed;
          }
        }
        &_title {
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          margin: 1rem 0;
          color: $title2;
          span {
            font-size: 35px;
            margin-right: 10px;
          }
        }
        &_info {
          margin-bottom: 1rem;
          height: 100%;
          max-height: 84px;
          font-size: 16px;
          line-height: 28px;
          color: $fade555;
          overflow-y: auto;

          &.info_full {
            max-height: 380px;
          }
        }
      }
    }
  }
}
.emmaccen {
  h1 {
    // font-size: 3.5rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal;
  }
  * a {
    text-decoration: none !important;
  }

  ul {
    list-style-type: disc;
  }
  //flex container
  @for $i from 1 through 10 {
    .flex-#{$i} {
      flex: $i;
    }
  }
  //flex container
  @for $i from 1 through 10 {
    .flexItem-#{$i} {
      flex: $i;
    }
  }

  //grid container
  @for $i from 1 through 7 {
    .grid-#{$i} {
      display: grid;
      gap: 3%;
      grid-template-columns: repeat($i, 1fr);
    }
  }
  //grid container
  @for $i from 1 through 5 {
    .gridItem-#{$i} {
      display: grid;
      grid-template-columns: repeat($i, 1fr);
      gap: 30px;
    }
  }

  .border {
    border: 1px solid rgba(227, 227, 227, 1);
    border-radius: $radius;
  }
  .flex-space-btw {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-jst-right {
    display: flex;
    justify-content: flex-end;
  }

  * {
    box-sizing: border-box;
  }
  .attachFileModal {
    .title {
      font-weight: 600;
      font-size: 18px;
    }
    .details {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #837171;
    }
    .fileUpload input[type='file'] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .fileUpload {
      background: rgba(39, 61, 242, 0.05);
      border: 1px dashed #273df2;
      border-radius: 4px;
    }
  }

  .pointer {
    cursor: pointer;
  }
  .textCenter {
    text-align: center;
  }
  .grid {
    display: grid;
    grid-auto-flow: column;
  }
  .grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .underline {
    bottom: -10px;
    left: 0px;
  }
  .blueBg {
    background-color: $primaryColor;
  }
  button {
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: $radius;
  }
  button:not(.ant-btn)::role(button) {
    // padding: 8px;
  }
  .border-rounded {
    border: 1.5px solid black;
    border-radius: 9999px;
    padding: 8px 16px;
  }
  .homeShadeBg {
    background-color: $homeShadeBg;
  }
  .brownishColor {
    color: $brownishColor;
  }
  .paragraph {
    color: $fadeText;
  }
  .fade2 {
    color: $fade2;
  }
  .borderShade1 {
    border: 1px solid $shade1;
  }
  .fade3 {
    color: $fade3;
  }
  .fade5 {
    color: $fade5;
  }
  .descriptionTextColor {
    color: $descriptionTextColor;
  }
  .title {
    color: $title;
  }
  .bold {
    font-weight: bold;
  }
  .font16 {
    font-size: 16px;
  }
  .header {
    h1 {
      font-weight: bold;
    }
    p {
      font-weight: 500;
    }
  }

  .flex {
    display: flex;
  }

  .backgroundFix {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .container1 {
    width: 93%;
    margin: auto;
  }
  .container2 {
    width: 80%;
    margin: auto;
  }
  .iconMargin {
    margin-left: 10px;
  }
  .actionText {
    color: $primaryColorLight;
    display: flex;
    align-items: center;
    font-weight: 700;
    svg {
      margin-left: 10px;
    }
  }
  .pad {
    padding: 5% 0px;
  }
  .spacer {
    margin: 100px 0px;
  }
  .btnPrimary,
  .btnGhost,
  .btnDashed {
    background-color: $primaryColor;
    color: #fff;
    padding: 8px 26px;
    font-size: 16px;
    font-weight: 500px;
    border-radius: 4px;
    margin: 0px 7px;
  }
  .btnGhost {
    background-color: white;
    color: #717171;
  }
  .btnRounded {
    background-color: #fff;
    color: $usePurple;
    padding: 10px 20px;
    border-radius: 20px;
    margin: 0px 7px;
    font-size: 12px;
    font-weight: bold;
  }
  [contenteditable='true']:hover,
  [contenteditable='true']:focus {
    background-color: rgba(217, 245, 255, 0.5);
    outline: none;
  }
  .ant-btn-primary {
    background-color: $primaryColor;
  }
  .margin-t-b {
    margin: 20px 0px;
  }
  .elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .relative {
    position: relative;
  }
  .overlay {
    position: absolute;
    content: '';
  }
  .usePurple {
    color: $usePurple;
  }
  .primaryColor {
    color: $primaryColor;
  }
  .primaryColorLight {
    color: $primaryColorLight;
  }
  .successColorLight {
    color: $useGreen;
  }
  .successColorLightBg {
    background-color: $useGreen;
  }
  .warningColorBg {
    background-color: $useYellow;
  }
  .dangerColorBg {
    background-color: $useRed;
  }
  .skyColorBg {
    background-color: $useBlue;
  }
  .blueBorder {
    border: 2px solid rgba(39, 61, 242, 0.3);
  }
  .card {
    box-shadow: 0px 4px 14px rgba(39, 61, 242, 0.05);
    background-color: #fff;
    padding: 40px;
    // width: 350px;
    border-radius: $radius;
    height: auto;
    // margin-right: 50px;
    // margin-top: 50px;
    // &:last-child {
    //   margin-right: 0px;
    // }
  }
  hr {
    margin: 10px 0px;
  }
  .grapesForMobileWidth {
    min-width: 900px;
    overflow-x: auto;
  }
  .feedbackForm {
    .gap {
      margin: 30px 0px 30px 0px;
    }
    .feedTitle {
      font-size: 16px;
      font-weight: 600;
    }
    .contain {
      border: 1px solid rgba($primaryColor, 0.1);
      border-radius: 5px;
      .switch {
        text-align: center;
        min-width: 100px;
        cursor: pointer;
        color: $descriptionTextColor;
        background-color: #fff;
        padding: 8px 11px;
        font-weight: 600;
        font-size: 12px;
        border-radius: 5px 0px 0px 5px;
        &:nth-child(2) {
          border-radius: 0px 5px 5px 0px;
        }
      }
      .blueBg {
        color: #fff;
        background-color: $primaryColor;
      }
    }
    textarea {
      background: #fbfbff;
      border: 1px solid rgba(39, 61, 242, 0.26);
      border-radius: 5px;
      width: 100%;
      min-height: 185px;
      max-height: 250px;
      outline: none;
      padding: 10px;
    }
    .rangeContainer {
      p {
        color: #383838;
        font-size: 14px;
      }
      span {
        color: $fade4;
        font-size: 12px;
      }
      margin-bottom: 30px;
    }
    .submit {
      min-width: 250px;
    }
  }
  .feedbackToggle {
    position: fixed;
    z-index: 10;
    bottom: 20px;
    right: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 7px;
    box-shadow: 0px 4px 14px rgba(39, 61, 242, 0.4);
    cursor: pointer;
    @media (max-width: 500px) {
      bottom: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
    }
  }
  .shake {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-3px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(3px, 0, 0);
    }
  }

  .editorHeaderTab {
    white-space: nowrap;
    overflow: auto;
    border-bottom: 1px solid #e3e3e3;
    padding: 10px 3.5%;
    margin: auto;
    .actions {
      margin-left: 30px;
    }
    .primaryBtn,
    .ghostBtn {
      font-weight: 500;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      transition: $ease;
      &:hover {
        outline: 1px solid $primaryColor;
        color: $primaryColor;
        border: none;
        background: rgba(39, 61, 242, 0.05);
        & svg path {
          fill: $primaryColor;
        }
      }
    }
    .ghostBtn {
      color: $descriptionTextColor;
      border: 1px solid #e3e3e3;
    }
    @media (max-width: 1000px) {
      padding: 10px 5%;
      @media (max-width: 700px) {
        padding: 10px 2%;
      }
    }
  }
  .emailPreviewContainer {
    .fullPreviewModeContainer {
      position: fixed;
      transition: all ease 1s;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 200;
      backdrop-filter: blur(5px);
      .previewFullMode {
        background: #ffffff;
        box-shadow: 0px 6px 38.4354px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 5%;
        max-height: 85%;
        max-width: 85%;
        overflow: auto;
        .previewTitle {
          font-size: 28px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 50px;
          color: $titleDeep;
        }
        .files {
          border-top: 1px solid rgba(0, 0, 0, 0.05);
          margin-top: 40px;
          padding: 20px 0px 50px 0px;
          cursor: pointer;
          .content {
            transition: 1s all ease;
            box-shadow: 0px 6px 38.4354px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            padding: 10px;
            text-align: center;
            margin-right: 10px;
            width: 100px;
            flex-wrap: wrap;
            h1 {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            &:hover {
              transform: scale(1.1);
            }
            svg {
              height: 30px;
              width: 30px;
              margin-bottom: 5px;
              // color: #ddd;
            }
          }
        }
      }
    }
    .campaignePreview {
      max-height: 450px;
      overflow-y: auto;

      border: 1px solid $shade1;
      padding: 20px;
      padding-top: 15px;
      // background-color: #fff;
      border-radius: $radius;
      .borderBottom {
        border-bottom: 1px solid $shade1;
        padding-bottom: 10px;
      }
      .userProfile {
        img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #383838;
        }
        .fade3 {
          font-weight: 500;
          font-size: 16px;
          margin-top: 0;
        }
      }
      .subject {
        font-weight: 500;
        font-size: 16px;
        color: $fade3;
      }
      .previewText {
        font-weight: 500;
        font-size: 16px;
        color: #383838;
        margin-top: 5px;
      }
      @media (max-width: 900px) {
        display: none;
      }
    }
  }
  .breadCrumbContainer {
    white-space: nowrap;
    // overflow: auto;
    .crumb {
      margin-right: 10px;
      color: $fade4;
      font-size: 16px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      svg {
        margin-left: 10px;
      }
    }
    & .crumb:last-child svg {
      display: none;
    }
  }
  .formLabel {
    color: $fade555;
    display: block;
  }
  .TextCrop {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .ant-modal-content {
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  .ant-alert-info {
    background-color: $alertInfo;
    font-size: 12px;
    font-weight: 500;
    .ant-alert-message {
      color: $descriptionTextColor;
    }
  }
  .antdTable {
    table {
      min-width: 400px;
    }
    .ant-table-cell {
      min-width: 100px;
    }
    td.ant-table-selection-column {
      min-width: 50px !important;
    }
    .ant-switch {
      padding: 0px;
      border-radius: 100px !important;
    }
    .actionIcons {
      display: flex;
      font-size: 18px;
      svg {
        margin-right: 20px;
      }
      .deleteIcon {
        color: #f45b5b;
      }
    }
    * {
      white-space: nowrap;
    }
    .ant-table-cell {
      background: none;
    }
  }
  .ant-tabs-content-holder {
    overflow-y: auto;
    margin-bottom: 100px;
  }
  .ant-tabs-tab-btn {
    color: #000;
  }
  .ant-tabs-nav {
    border-bottom: 1px solid $shade1;
    // border-radius: $radius;
    // margin-top: 20px;
    background-color: #fff;
    padding: 10px 15px;
    &::before {
      border: none;
    }
    .ant-tabs-tab-btn {
      color: $fade4;
      font-size: 16px;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primaryColor;
    }
    .ant-tabs-ink-bar {
      background-color: #fff;
    }
  }
  .ant-input-search-button {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ant-input-search {
    .ant-input-affix-wrapper,
    .ant-input {
      background-color: $shade1;
    }
  }
  .iconList svg {
    margin-right: 15px;
    color: #fff;
    font-size: 20px;
  }

  .navBar {
    font-style: capitalize;
    padding: 20px 5%;
    box-shadow: 0px 1px 0px #e5e9f2;
    .mobileController {
      svg {
        display: none;
        font-size: 20px;
      }
    }
    .navLinks {
      a {
        color: $titleDeep;
        font-size: 15px;
        font-weight: 500;
        margin-right: 30px;
      }
    }

    @media (max-width: 900px) {
      padding: 20px 3%;
      .container1 {
        width: 100%;
      }
      @media (max-width: 680px) {
        padding: 20px 5%;
        .flex-space-btw {
          flex-direction: column;
          align-items: flex-start;
        }
        .navLinks {
          margin-top: 20px;
          a {
            margin-bottom: 20px;
          }
          .btnPrimary {
            margin-left: 0;
          }
        }
        .mobileController {
          width: 100%;
          display: flex;
          justify-content: space-between;
          svg {
            display: block;
          }
        }
      }
    }
  }
  .radius-50 {
    border-radius: 50%;
  }

  // ant form control

  .ant-row.ant-form-item {
    margin-bottom: 34px;
  }
  .ant-col.ant-form-item-label {
    .ant-form-item-required {
      height: auto !important;
    }
    .ant-form-item-required::before {
      display: none;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    padding: 7px 6px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: auto !important;
    padding: 5px 6px;
  }

  // nav for authenticated users
  .authNavBar {
    // max-width: calc(100% - 190px);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    font-style: capitalize;
    padding: 10px 15px;
    box-shadow: 0px 1px 0px #e5e9f2;
    z-index: 100;
    background-color: #fff;
    .clt {
      font-weight: 500;
      font-size: 18px;
      color: #45505b;
    }
    svg {
      font-size: 20px;
    }
    .logo_container-desktop {
      display: none;
    }
    .current_loggedin_user {
      align-items: center;
      div {
        margin: 0 10px;
        p:first-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $titleDark;
        }
        p:last-child {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: $fade3;
          margin-top: 2px;
        }
      }

      @media (max-width: 600px) {
        display: flex !important;
        margin-top: 1rem;
      }
    }
    .whats_new {
      background-color: $lightRedBg;
      border-radius: 2px;
      padding: 10px 14px;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 14px;
      color: $useDeepRed !important;

      img {
        margin-right: 10px;
      }
    }
    @media (max-width: 600px) {
      width: 100% !important;
      .content,
      .mobileController,
      .profileSection {
        display: block;
      }
      .logo_container-desktop {
        display: flex;
        align-items: center;

        .title {
          font-weight: 600;
          font-size: 21.039px;
          line-height: 22px;
          color: $primaryColor;
        }
      }
      .profileSection {
        padding-left: 42px;
        .centered {
          justify-content: flex-start;
        }
        .bell,
        img {
          margin-top: 15px;
          border-radius: 50%;
        }
      }
      .content,
      .mobileController {
        .hamburger {
          svg {
            display: block;
          }
        }
        .navLinks {
          justify-content: flex-start;
          padding-left: 42px;
          .flex {
            display: block;
            a {
              display: block;
              margin-top: 15px;
            }
          }
        }
      }
    }
    .mobileController {
      svg {
        display: none;
      }
    }
    .navLinks {
      a {
        color: $titleDeep;
        font-size: 15px;
        font-weight: 500;
        margin-right: 30px;
      }
      .tomato {
        background: #ec615b;
        border-radius: 5px;
        padding: 3px 8px;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
      }
    }
    .logo {
      height: 40px;
      width: 40px;
    }
  }
  //navbar profile Dropdown
  .profileDropdown {
    border-radius: 5px;
    // padding: 10px;
    .accountDetails {
      // margin-bottom: 30px;
      p {
        margin: 0px;
      }
      & p:nth-child(1) {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
      }
      & p:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: $fade3;
      }
      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-left: 10px;
      }
    }
    .menuItem {
      // margin-bottom: 15px;
      display: block;
      &:last-child {
        margin: 0px;
      }
      a {
        font-size: 16px;
        color: $fade5;
        font-weight: normal;
        text-decoration: none;
        align-items: center;
        svg {
          font-size: 20px;
          margin-right: 18px;
          color: #717171;
        }
      }
    }
  }
  .footer {
    margin-top: 150px;
    background-color: $footerDark;
    color: $paragraph;
    .copyright {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 60px;
      margin-top: 50px;
    }
    @media (max-width: 850px) {
      .grid3,
      .copyright {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
      @media (max-width: 500px) {
        .grid3,
        .copyright {
          grid-template-columns: 1fr;
        }
      }
    }

    // .logo {
    //   color: #fff;
    // }
    .footerContent {
      .aboutMailDrip {
        width: 70%;
        @media (max-width: 500px) {
          width: 90%;
        }
      }
      a {
        display: block;
        margin-bottom: 15px;
      }
      .linkHeader {
        margin-bottom: 20px;
        .logo,
        h3 {
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }
}

// contact group modification pop
.modify-group_pop {
  bottom: -40px;
  width: 100%;
  max-width: 500px !important;
  border-radius: 4px;
  height: 60px;
  // display: flex;
  // align-items: center;

  .ant-notification-notice-description {
    display: none;
  }

  // .ant-notification-notice-content > div {
  //   display: flex;
  //   align-items: center;
  // }

  .modify-group_btn {
    background: #273df2;
    border-color: #273df2;
    border-radius: 4px;
  }
  .modify-group_text {
    margin-top: 3px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
}

.ant-modal {
  top: 30px !important;

  &-body {
    padding: 5px 32px 25px 32px;
  }
}

// import CSV wrapper
.csvreader-container {
  background-color: rgba(39, 61, 242, 0.05);
  border: 1px dashed $primaryColor;
  border-radius: 4px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  .aboutto_upload {
    display: flex;
    flex-direction: column;
    color: #827171;
    text-align: center;
  }
}

.getstarted_samplesheet {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: #827171;
  & > div {
    text-align: center;
    a,
    button {
      color: $primaryColor;
      text-align: center;
      margin: 0 auto;
      // font-weight: bold;
    }
  }
}

// Global ant collapse arrow icons
.ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11 6L6 1L1 6' stroke='%2325282B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") !important;
  display: inline-block;
  transition: transform 0.24s;
  // transform: rotate(90deg);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L6 6L11 1' stroke='%2325282B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  display: inline-block;
  transition: transform 0.24s;
  // transform: rotate(90deg);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #fff;
}
